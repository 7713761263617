<template>
  <div class="bg-white border rounded">
    <div class="p-3">
      <div class="d-flex">
        <div class="w-50 pr-2">
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ID
            </div>
            <div class="ml-2 text-break">
              {{ consultation.id || '—' }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div
              v-if="consultation.type === 0"
              class="font-weight-bold"
            >
              ФИО
            </div>
            <div
              v-if="consultation.type === 1"
              class="font-weight-bold"
            >
              Консультируемый врач
            </div>
            <div class="ml-2 text-break">
              {{ patientFullname }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Статус
            </div>
            <div
              :style="{'color': statusColor}"
              class="ml-2 text-break"
            >
              {{ status }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div
              v-if="consultation.type === 0"
              class="font-weight-bold"
            >
              {{ consultation.targetPatient ? 'Телефон представителя' : 'Телефон пациента' }}
            </div>
            <div
              v-if="consultation.type === 1"
              class="font-weight-bold"
            >
              Телефон врача
            </div>
            <div class="ml-2 text-break">
              {{ consultation.patient.phoneNumber || '-' }}
            </div>
          </div>
          <div class="d-flex flex-wrap mb-1">
            <div class="font-weight-bold mb-1 mr-2">
              Клиника
            </div>
            <div
              class="text-break"
            >
              {{ consultation.doctor.clinicTitle }}
            </div>
          </div>
        </div>
        <div class="w-75">
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ФИО врача
            </div>
            <div class="ml-2 text-break">
              {{ doctorFullname }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Телефон врача
            </div>
            <a
              v-if="doctorPhoneNumber"
              :href="`tel:${doctorPhoneNumber}`"
              class="ml-2 text-break"
            >
              {{ doctorPhoneNumber }}
            </a>
            <div
              v-else
              class="ml-2 text-break"
            >
              -
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Специальность врача
            </div>
            <div class="ml-2 text-break">
              {{ doctorSpecialization }}
            </div>
          </div>
          <div class="my-divider" />
          <div class="d-flex">
            <div class="w-50">
              <div class="d-flex mb-1">
                <div class="font-weight-bold">
                  Время создания
                </div>
                <div class="ml-2 text-break">
                  {{ consultation.createDate ? parseData(consultation.createDate) : '—' }}
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="font-weight-bold">
                  Запись на
                </div>
                <div class="ml-2 text-break">
                  {{ consultation.slotDate ? formatDateWithTimeZone(consultation.slotDate) : '—' }}
                </div>
              </div>
              <div
                v-if="consultation.differenceSlotTakeOut"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Запись — Взятие
                </div>
                <div class="ml-2 text-break">
                  {{ differenceSlotTakeOut }}
                </div>
              </div>
            </div>
            <div class="my-divider my-divider_vertical" />
            <div class="w-50">
              <div class="d-flex mb-1">
                <div class="font-weight-bold">
                  Взято в работу
                </div>
                <div class="ml-2 text-break">
                  {{ consultation.takeOutDate ? parseData(consultation.takeOutDate) : '—' }}
                </div>
              </div>
              <div
                v-if="consultation.closeDate"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Закрыто
                </div>
                <div class="ml-2 text-break">
                  {{ parseData(consultation.closeDate) }}
                </div>
              </div>
              <div
                v-if="consultation.differenceTakeOutClose"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Взятие — Закрытие
                </div>
                <div class="ml-2 text-break">
                  {{ differenceTakeOutClose }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ml-3 d-flex flex-column">
          <!-- <icon-chat class="consultation-control" /> -->
          <div class="d-flex">
            <b-button
              v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.chatConsultation)"
              v-b-tooltip.hover.left
              variant="secondary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2 mr-2 p-1"
              title="Просмотр чата"
              @click="openConsultationChatModal"
            >
              Чат
            </b-button>

            <b-button
              v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.historyOfChanges)"
              v-b-tooltip.hover.left
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="flex-shrink-0 mt-2 ml-auto p-1"
              title="История изменений"
              @click="openConsultationHistoryModal"
            >
              <b-icon icon="book" />
            </b-button>
          </div>
          <template v-if="isShowConsultationControls">
            <div class="d-flex">
              <b-button
                v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.historyOfConsultationReopenings)"
                v-b-tooltip.hover.left
                variant="outline-success"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2"
                title="История переоткрытий консультации"
                @click="openHistoryConsultationReopeningModal"
              >
                <b-icon
                  icon="book-half"
                />
              </b-button>
              <b-button
                v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.transferСonsultation)"
                v-b-tooltip.hover.left
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2 ml-auto p-1"
                title="Перенос консультации"
                @click="openTransferModal"
              >
                <b-icon icon="arrow-left-right" />
              </b-button>
            </div>

            <b-button
              v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.deletingConsultation)"
              v-b-tooltip.hover.left
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2 ml-auto p-1"
              title="Удаление консультации"
              @click="openCancelModal"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
          <template v-if="statusClosed">
            <div class="d-flex flex-wrap">
              <b-button
                v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.historyOfConsultationReopenings)"
                v-b-tooltip.hover.left
                variant="outline-success"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2"
                title="История переоткрытий консультации"
                @click="openHistoryConsultationReopeningModal"
              >
                <b-icon
                  icon="book-half"
                />
              </b-button>
              <b-button
                v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.reopenСonsultation)"
                v-b-tooltip.hover.left
                variant="success"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2 ml-auto p-1"
                title="Переоткрытие консультации"
                @click="reopeningConsultation"
              >
                <b-icon
                  icon="arrow-clockwise"
                />
              </b-button>
              <b-button
                v-if="checkFeature(FEATURES_FOR_CONSULTATIONS.protocol)"
                v-b-tooltip.hover.left
                variant="outline-success"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="mt-2"
                title="Протокол"
                @click="getProtocol"
              >
                <b-icon
                  icon="file-earmark-pdf"
                />
              </b-button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="isInfoShow"
      class="p-3"
    >
      <div class="d-flex flex-wrap justify-content-between">
        <div class="mr-4 mb-3">
          <div class="font-weight-bold">
            Способ связи
          </div>
          <div class="mt-2 text-break">
            {{ communicationMethods[consultation.communicationMethod] }}
          </div>
        </div>
        <div
          v-if="checkFeatureAccess({ name: 'Видеть режим консультации', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Режим кон-ции
          </div>
          <div class="mt-2 text-break">
            {{ consultationTypes[consultation.type] }}
          </div>
        </div>
        <div
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Вид консультации
          </div>
          <div class="mt-2 text-break">
            {{ consultationKind[consultation.kind] }}
          </div>
        </div>
        <div class="mr-4 mb-3">
          <div
            v-if="checkFeatureAccess({ name: 'Видеть поле Начальная стоимость', url: '/consultations' })"
            class="font-weight-bold"
          >
            Начальная стоимость
          </div>
          <div class="mt-2 text-break">
            {{ startPrice }}
          </div>
        </div>
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Конечная стоимость', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Конечная стоимость
          </div>
          <div class="mt-2 text-break">
            {{ consultation.cost }} ₽
          </div>
        </div>
        <!-- <div class="mr-4 mb-3">
          <div class="font-weight-bold">
            Часовой пояс врача
          </div>
          <div class="mt-2 text-break">
            {{ timeZone }}
          </div>
        </div> -->
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Промокод', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Промокод
          </div>
          <div class="mt-2 text-break">
            {{ consultation.promocode || '—' }}
          </div>
        </div>
        <!--          <div class="mr-4 mb-3">-->
        <!--            <div class="font-weight-bold">-->
        <!--              Оценка кон-ции-->
        <!--            </div>-->
        <!--            <div class="mt-2 text-break">-->
        <!--              {{ consultation.rating || '—' }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!-- <div class="mr-4 mb-3">
          <div class="font-weight-bold">
            Режим кон-ции
          </div>
          <div class="mt-2 text-break">
            {{ consultationTypes[consultation.type] }}
          </div>
        </div> -->
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Платформа', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Платформа
          </div>
          <div class="mt-2 text-break">
            {{ clientTypes[consultation.clientType] }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Код оплаты', url: '/consultations' }) && consultation.paymentOrderId"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Код оплаты
          </div>
          <div class="mt-2 text-break">
            {{ consultation.paymentOrderId || '—' }}
          </div>
        </div>
      </div>
      <div class="my-divider" />
      <div class="d-flex mb-1">
        <div class="font-weight-bold">
          Текст обращения
        </div>
        <div class="ml-2 text-break">
          {{ consultation.body }}
        </div>
      </div>
      <template v-if="consultation.protocol">
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Рекомендации
          </div>
          <div class="ml-2 text-break">
            {{ consultation.protocol.recomendation || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Жалобы
          </div>
          <div class="ml-2 text-break">
            {{ consultation.protocol.anamnesis || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            МКБ
          </div>
          <div
            v-if="!consultation.protocol.mkB10s?.length"
            class="ml-2 text-break"
          >
            —
          </div>
          <template v-else>
            <div
              v-for="(mkB10, index) in consultation.protocol.mkB10s"
              :key="index"
              class="ml-2 text-break"
            >
              {{ `${mkB10.code} — ${mkB10.name}` }}
            </div>
          </template>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Направления:
          </div>
          <div
            v-if="!consultation.laboratoryServiceReferral.services?.length"
            class="ml-2 text-break"
          >
            —
          </div>
          <template v-else>
            <div class="ml-3">
              <div
                v-for="item in consultation.laboratoryServiceReferral.services"
                :key="item.serviceId"
                class="mb-1"
              >
                <span>-  {{ item.name }}</span>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div
      style="cursor: pointer;"
      class="bg-primary rounded-bottom d-flex justify-content-center p-2"
      @click="isInfoShow = !isInfoShow"
    >
      <b-icon
        icon="caret-down-fill"
        variant="light"
        :rotate="isInfoShow ? 180 : 0"
      />
    </div>
  </div>
</template>

<!-- Рефакторинг не производился. Перевёрстан под бутстрап -->
<script>
import { FEATURES_FOR_CONSULTATIONS } from '@/helpers/consts';
import { saveFile, utc0ToDateWithUserTimezone } from '@/helpers/utils';
import { consultationService } from '@/services';
import {
  parseISO,
  format,
} from '@evd3v/date-fns';

import { mixinRoles } from '@/mixins';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'Consultationinfo',

  mixins: [mixinRoles],
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isInfoShow: false,
      consultationTypes: {
        0: 'Пациент-Врач',
        1: 'Врач-Врач',
      },
      consultationKind: {
        0: 'Срочная',
        1: 'Плановая',
        2: 'Отложенная',
      },
      statuses: {
        0: {
          title: 'Открыта',
          value: 'green',
        },
        1: {
          title: 'В работе',
          value: 'blue',
        },
        2: {
          title: 'Закрытые',
          value: 'red',
        },
        3: {
          title: 'Консилиум',
          value: 'blue',
        },
        4: {
          title: 'Ожидание оплаты',
          value: 'blue',
        },
        6: {
          title: 'Отмена',
          value: 'red',
        },
        7: {
          title: 'Ожидание оплаты истекло',
          value: 'orange',
        },
        8: {
          title: 'Отменено пациентом',
          value: 'red',
        },
        9: {
          title: 'Отменено доктором',
          value: 'red',
        },
        11: {
          title: 'Ошибочный статус',
          value: 'red',
        },
      },
      clientTypes: {
        0: 'Неизвестно',
        1: 'Android Doctis',
        2: 'iOS Doctis',
        3: 'Веб',
        4: 'CRM',
        5: 'Запись врачом',
        11: 'Android FoodFessional Generation',
        12: 'iOS FoodFessional Generation',
        101: 'Внешний сервис',
        102: 'СМЭВ',
      },
      communicationMethods: {
        0: 'Текст',
        1: 'Аудио',
        2: 'Видео',
      },
      FEATURES_FOR_CONSULTATIONS,
    };
  },
  computed: {
    isShowConsultationControls() {
      return ['В работе', 'Открыта', 'Ожидание оплаты'].includes(this.status);
    },
    statusClosed() {
      return ['Закрытые'].includes(this.status);
    },
    status() {
      return this.statuses[this.consultation.status].title;
    },
    statusColor() {
      return this.statuses[this.consultation.status].value;
    },
    rating() {
      return this.consultation.rating || 'Отсутствует';
    },
    doctorSpecialization() {
      return this.consultation.doctor.specializationTitle;
    },
    patientFullname() {
      const { middleName, firstName, lastName } = this.consultation.patient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    targetPatientFullname() {
      const { middleName, firstName, lastName } = this.consultation.targetPatient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    doctorFullname() {
      try {
        const { middleName, firstName, lastName } = this.consultation.doctor;

        return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    doctorPhoneNumber() {
      try {
        return this.consultation.doctor.phoneNumber;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    // timeZone() {
    //   return this.consultation.timeZone ? formatTimeZone(this.consultation.timeZone) : null;
    // },
    differenceSlotTakeOut() {
      const difference = this.consultation.differenceSlotTakeOut.replace('-', '');
      const hasDays = difference.includes('.');
      const days = difference.split('.')[0];
      const daysString = hasDays ? `${+days}д` : '';

      const differenceArray = hasDays ? difference.split('.')[1].split(':') : difference.split(':');

      const hours = differenceArray[0];
      const hasHours = !!+hours;
      const hoursString = hasHours ? `${hours}ч` : '';

      const minutes = differenceArray[1];
      const hasMinutes = !!+minutes;
      const minutesString = hasMinutes ? `${minutes}м ` : '';

      const seconds = differenceArray[2];
      const secondsString = `${seconds}с `;

      return `${daysString} ${hoursString} ${minutesString} ${secondsString}`;
    },
    differenceTakeOutClose() {
      const difference = this.consultation.differenceTakeOutClose.replace('-', '');
      const hasDays = difference.includes('.');
      const days = difference.split('.')[0];
      const daysString = hasDays ? `${+days}д` : '';

      const differenceArray = hasDays ? difference.split('.')[1].split(':') : difference.split(':');

      const hours = differenceArray[0];
      const hasHours = !!+hours;
      const hoursString = hasHours ? `${+hours}ч` : '';

      const minutes = differenceArray[1];
      const hasMinutes = !!+minutes;
      const minutesString = hasMinutes ? `${minutes}м ` : '';

      const seconds = differenceArray[2];
      const secondsString = `${seconds}с `;

      return `${daysString} ${hoursString} ${minutesString} ${secondsString}`;
    },
    startPrice() {
      const price = this.consultation.doctor.cost;
      return price ? `${price} ₽` : '—';
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    openConsultationHistoryModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationHistoryModal',
        props: {
          consultationId: this.consultation.id,
        },
      });
    },
    openHistoryConsultationReopeningModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'HistoryConsultationReopeningModal',
        props: {
          consultationId: this.consultation.id,
        },
      });
    },
    openConsultationChatModal() {
      uiService.showModal(MODALS.CHAT_CONSULTATION_MODAL, {
        name: 'ConsultationChatModal',
        props: {
          folderName: this.consultation.folderOtherId,
          consultationId: this.consultation.id,
          hasRecord: this.consultation.hasRecord,
        },
      });
    },
    parseData(ISODate) {
      if (!ISODate) {
        return null;
      }

      return format(parseISO(ISODate), 'dd.MM.yyyy HH:mm');
    },
    formatDateWithTimeZone(date) {
      const newDate = utc0ToDateWithUserTimezone(date);
      return format(new Date(newDate), 'dd.MM.yyyy HH:mm');
    },
    async getProtocol() {
      const protocol = await consultationService.getProtocol(this.consultation.id);
      const blob = new Blob([protocol], { type: 'application/pdf' });
      await saveFile(blob, { name: 'this.originName', open: true });
    },
    openTransferModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationTransferModal',
        props: {
          consultationProp: this.consultation,
        },
      });
    },
    reopeningConsultation() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationReopeningModal',
        props: {
          consultationId: this.consultation.id,
        },
      });
    },
    openCancelModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationDeletingModal',
        props: {
          consultation: this.consultation,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(136, 136, 136, 0.5);
  margin: 10px 0;

  &_vertical {
    width: 1px;
    height: 50px;
    margin: 0 10px;
  }
}
</style>
